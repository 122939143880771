<template>
  <page-header-wrapper>
    <a-card :bordered="false">
      <!-- 条件搜索 -->
      <div class="table-page-search-wrapper">
        <a-form layout="inline">
          <a-row :gutter="48">
            <a-col :md="6" :sm="24">
              <a-form-item label="图片名称" prop="name">
                <a-input v-model="queryParam.name" placeholder="请输入型号名称" allow-clear/>
              </a-form-item>
            </a-col>
            <a-col :md="6" :sm="24">
              <a-form-item label="设备型号" prop="deviceModelIds">
                <a-select v-model="queryParam.deviceModelIds" placeholder="请选择" allow-clear>
                  <a-select-option v-for="(item,key) in deviceTypeList" :key="key" :value="item.id">{{ item.name }}</a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
            <a-col :md="!advanced && 6 || 24" :sm="24">
              <span class="table-page-search-submitButtons" :style="advanced && { float: 'right', overflow: 'hidden' } || {} ">
                <a-button type="primary" @click="handleQuery"><a-icon type="search" />查询</a-button>
                <a-button style="margin-left: 8px" @click="resetQuery"><a-icon type="redo" />重置</a-button>
              </span>
            </a-col>
          </a-row>
        </a-form>
      </div>
      <!-- 数据展示 -->
      <a-table
        :loading="loading"
        :size="tableSize"
        rowKey="id"
        :columns="columns"
        :data-source="list"
        :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }"
        :pagination="false"
        :bordered="tableBordered"
      >
        <span slot="pic" slot-scope="text, record">
          <viewer :images="[record.picPathStr]">
            <img style="width:50px;height:50px;object-fit: cover;margin-right:5px" :src="record.picPathStr">
          </viewer>
        </span>
        <span slot="type" slot-scope="text, record">
          <span v-if="record.type == 0">系统图库</span>
          <span v-if="record.type == 1">用户图库</span>
        </span>
        <span slot="deviceModelName" slot-scope="text, record">
          <a-tooltip>
            <template slot="title">{{ record.deviceModelName ? record.deviceModelName : '暂无' }}</template>
            <a>查看</a>
          </a-tooltip>
        </span>
        <span slot="createTime" slot-scope="text, record">
          {{ parseTime(record.createTime) }}
        </span>
      </a-table>
      <!-- 分页 -->
      <a-pagination
        class="ant-table-pagination"

        :current="queryParam.pageNum"
        :total="total"
        :page-size="queryParam.pageSize"
        :showTotal="total => `共 ${total} 条`"
        @showSizeChange="onShowSizeChange"
        @change="changeSize"
      />
    </a-card>
  </page-header-wrapper>
</template>

<script>
import { pageDeviceType } from '@/api/valve/deviceType'
import { pagePicture, delPicture } from '@/api/valve/gallery'
import { tableMixin } from '@/store/table-mixin'

export default {
  name: 'Picture',
  components: {
  },
  mixins: [tableMixin],
  data () {
    return {
      list: [],
      selectedRowKeys: [],
      selectedRows: [],
      // 高级搜索 展开/关闭
      advanced: false,
      // 非单个禁用
      single: true,
      // 非多个禁用
      multiple: true,
      deviceTypeList: [],
      ids: [],
      loading: false,
      total: 0,
      userId: null,
      // 查询参数
      queryParam: {
        sortField: 'createTime',
        sortOrder: 'DESCEND',
        deviceModelIds: undefined,
        name: null,
        type: 1,
        pageNum: 1,
        pageSize: 10
      },
      columns: [
        {
          title: '图库名称',
          dataIndex: 'name',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '图库类型',
          dataIndex: 'type',
          scopedSlots: { customRender: 'type' },
          ellipsis: true,
          align: 'center'
        },
        {
          title: '设备型号',
          ellipsis: true,
          scopedSlots: { customRender: 'deviceModelName' },
          align: 'center'
        },
        {
          title: '图片',
          dataIndex: 'picPath',
          scopedSlots: { customRender: 'pic' },
          ellipsis: true,
          align: 'center'
        },
        {
          title: '用户姓名',
          dataIndex: 'userName',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '添加时间',
          dataIndex: 'createTime',
          scopedSlots: { customRender: 'createTime' },
          ellipsis: true,
          align: 'center'
        }
      ]
    }
  },
  filters: {
  },
  created () {
    if (this.$route.query && this.$route.query.uid) {
      this.userId = this.$route.query.uid
    }
    pageDeviceType({ type: 0, pageSize: 999999999, sortField: 'createTime', sortOrder: 'DESCEND' }).then(response => {
      this.deviceTypeList = response.data.records
    })
    this.getList()
  },
  computed: {
  },
  watch: {
  },
  methods: {
    /** 查询型号信息列表 */
    getList () {
      this.loading = true
     pagePicture({ userId: this.userId, ...this.queryParam }).then(response => {
        this.list = response.data.records
        this.total = response.data.total
        this.loading = false
      })
    },

    /** 搜索按钮操作 */
    handleQuery () {
      this.queryParam.pageNum = 1
      this.getList()
    },
    /** 重置按钮操作 */
    resetQuery () {
      this.queryParam = {
        sortField: 'createTime',
        sortOrder: 'DESCEND',
        deviceModelIds: undefined,
        name: null,
        type: 1,
        pageNum: 1,
        pageSize: 10
      }
      this.handleQuery()
    },
    onShowSizeChange (current, pageSize) {
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    changeSize (current, pageSize) {
      this.queryParam.pageNum = current
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    onSelectChange (selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
      this.selectedRows = selectedRows
      this.ids = this.selectedRows.map(item => item.id)
      this.single = selectedRowKeys.length !== 1
      this.multiple = !selectedRowKeys.length
    },
    toggleAdvanced () {
      this.advanced = !this.advanced
    },
    /** 删除按钮操作 */
    handleDelete (row) {
      var that = this
      const ids = row.id || this.ids
      if (row.id) {
        this.ids.push(row.id)
      }
      this.$confirm({
        title: '确认删除所选中数据?',
        content: '当前选中编号为' + ids + '的数据',
        onOk () {
          const data = []
          that.ids.map(id => data.push({ 'id': id }))
          return delPicture(data)
            .then(() => {
              that.onSelectChange([], [])
              that.getList()
              that.$message.success(
                '删除成功',
                3
              )
          })
        },
        onCancel () {}
      })
    },
    /** 导出按钮操作 */
    handleExport () {
      var that = this
      this.$confirm({
        title: '是否确认导出?',
        content: '此操作将导出当前条件下所有数据而非选中数据',
        onOk () {
          that.download('valve/project/export', {
            ...that.queryParam
          }, `型号信息_${new Date().getTime()}.xlsx`)
        },
        onCancel () {}
      })
    }
  }
}
</script>
